.error-state {
  background-color: rgb(189, 0, 0);
  color: white;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  text-align: center;
  border: 2px solid red;
  height: 60%;
  width: 98%;
  position: relative;
  top: -80px;
  margin: auto;
}
.error-span {
  display: block;
  padding: 0.5rem;
  font-size: 1rem;
  color: red;
  font-weight: bold;
  background-color: white;
  margin-top: 50px;
}
.admin {
  text-align: center;
  font-weight: bold;
  color: #2f00ff83;
  background-color: rgb(237, 237, 102);
}

.prod-header {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  align-items: center;
  padding: 0 10px 0px 0px;
  box-sizing: border-box;
  border: 1px solid black;
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 0 0rem;
  background-color: beige;
}

.grid-1 {
  grid-column-start: 1;
  font-weight: bold;
}
.grid-2 {
  grid-column-start: 2;
  font-weight: bold;
}
.grid-3 {
  grid-column-start: 3;
  font-weight: bold;
}
.grid-4 {
  grid-column-start: 4;
  font-weight: bold;
}
.grid-5 {
  grid-column-start: 5;
  font-weight: bold;
}
.grid-6 {
  grid-column-start: 6;
  font-weight: bold;
}
.grid-7 {
  grid-column-start: 7;
  font-weight: bold;
}
.grid-8 {
  grid-column-start: 8;
  font-weight: bold;
}
.grid-9 {
  grid-column-start: 9;
  font-weight: bold;
}
.grid-10 {
  grid-column-start: 10;
  font-weight: bold;
}
.grid-11 {
  grid-column-start: 11;
  font-weight: bold;
}
.grid-12 {
  grid-column-start: 12;
  font-weight: bold;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 24px 0;
  width: 100%;
}

.pagination-button {
  padding: 8px 16px;
  border: 1px solid #2f44ff;
  background-color: rgba(0, 0, 0, 0.507);
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.ellipsis {
  width: 10px;
  padding: 0 15px;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f0f0f0;
}

.pagination-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
