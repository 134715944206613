.active {
  /* background-color: rgb(9, 255, 0); */
  /* background-color: rgb(8, 0, 255); */
  box-shadow: 0 0 10px 2px;
  color: white;
}

.about-me-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  /* background-color: rgb(176, 176, 253);
  background: linear-gradient(270deg, #7777f3bd, #5b36ff83, #2f00ff83);
  background-size: 400% 400%;
  animation: ocean 10s ease-in-out infinite; */
  letter-spacing: 1.5px;
}
.about-me-div-adjust {
  margin: 16px 0;
}
@keyframes ocean {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.about-me-h1 {
  font-family: 'shadows into light', cursive;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding: 10px 40px;
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 grey;
  background-color: black;
}
.me {
  width: 40%;
  max-width: 250px;
  border: 2px solid #7777f3;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 grey;
  margin: -100px 0 20px 0;
}
.about-me-p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  padding: 15px 40px;
  color: #ffffff;
  background-color: black;
  box-shadow: 0 0 10px 0 grey;
  z-index: 1000;
  backdrop-filter: blur(5px) saturate(100%) contrast(100%) brightness(30%);
  width: 400px;
  margin: 0.75rem;
}
.about-me-link {
  display: inline-block;
  color: #7777f3;
  text-shadow: 0px 0px 1px #ffffff;
}
.about-me-link-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  width: 100%;
  margin: 40px 0 0 0;
}
.about-me-link-sub-container {
  grid-column: 1/5;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 75px;
}
.about-me-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-logo {
  width: 50px;
  height: 50px;
  border: 1px solid #7777f3;
  box-shadow: 0 0 10px 0 grey;
  margin: 0 10px;
  border-radius: 15px;
}

/* rotate cube*/
.scene3 {
  width: 150px;
  height: 150px;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.cube3 {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  animation: rotate3 90s infinite linear;
  z-index: -1;
}

.face3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* border: 2px solid #5b36ff;
  background-color: #5b36ff; */
  border: 2px solid black;
  background-color: black;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgb(255, 255, 255);
  z-index: 1;
}
.front3 {
  transform: rotateY(0deg) rotateZ(90deg) translateZ(75px);
  background-image: url('/public/drumset.jpeg');
}

.bgVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.back3 {
  transform: rotateY(180deg) rotateZ(270deg) translateZ(75px);
  background-image: url('/public/action-2.jpg');
}

.right3 {
  transform: rotateY(90deg) translateZ(75px);
  background-image: url('/public/comet.jpg');
}

.left3 {
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(75px);
  background-image: url('/public/mt.jpeg');
}

.top3 {
  transform: rotateX(90deg) rotateZ(270deg) translateZ(75px);
  background-image: url('/public/mushrooms.JPG');
}

.bottom3 {
  transform: rotateX(-90deg) rotateZ(270deg) translateZ(75px);
  background-image: url('/public/knaildriver4.jpg');
}
.about-icon-adapt {
  position: relative;
  top: 50px;
}

@keyframes rotate3 {
  0% {
    transform: rotateX(60deg) rotateY(360deg) rotateZ(0deg) translateX(0px) translateY(-30px)
      scaleX(0.8) scaleY(0.8) scaleZ(0.8);
  }

  33% {
    transform: rotateX(540deg) rotateY(0deg) rotateZ(0deg) translateX(100px) translateY(0px)
      scaleX(0.8) scaleY(0.8) scaleZ(0.8);
  }

  66% {
    transform: rotateX(20deg) rotateY(540deg) rotateZ(0deg) translateX(0px) translateY(0px)
      scaleX(1.5) scaleY(1.5) scaleZ(1.5);
  }

  100% {
    transform: rotateX(60deg) rotateY(0deg) rotateZ(360deg) translateX(0px) translateY(-30px)
      scaleX(0.8) scaleY(0.8) scaleZ(0.8);
  }
}
@media (min-width: 768px) {
  .about-me-p {
    width: 800px;
  }

  .link-logo {
    width: 100px;
    height: 100px;
  }

  .me {
    margin: -150px 0 20px 0;
  }

  .about-me-link-sub-container {
    margin-top: 95px;
  }
}
