.inventory-container {
  margin-top: 20px;
  margin: 10px 10px 0 10px;
  border: none;
}
.inventory-container {
  margin: 0;
}
.inventory-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
  background-color: 'yellow' !important;
}

.inventory-table th,
.inventory-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  padding: 0 10px;
}

.inventory-table .MuiTableBody-root .MuiTableRow-root:not(:last-child):hover {
  box-shadow: 0 0 0 2px #beb3f283 inset;
  background-color: #2f00ff83;
}
.selectedRow {
  box-shadow: 0 0 0 2px #785eee inset;
  background-color: #2f00ff83;
}

.large-size-inventory {
  background-color: rgb(15, 15, 15);
  position: absolute;
  width: 100%;
  border: 1px solid #2f44ff;
}
/*
  
  
  @media BEGIN================================
  
  
  */

@media (min-width: 1200px) {
  .large-size-inventory {
    position: relative;
  }
}

/*  END 1024px----------------------------------------------------------  




1600px -----------------------------------------------------------------
*/
@media (min-width: 1600px) {
  .inventory-table th,
  .inventory-table td {
    border: 1px solid black;
    padding: 2px;
    text-align: left;
  }
  .large-size-inventory {
    position: unset;
    top: unset;
    width: unset;
  }
}
/* END 1600px----------------------------------------------------------  

*/
